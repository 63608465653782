import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Menu from "../views/Menu.vue";
import Curbside from "../views/Curbside.vue";
import goTo from "vuetify/es5/services/goto";

Vue.use(VueRouter);

const defaultTitle =
  "Pop-Up Chicken Shop | Illinois Hot Chicken | Bloomington, IL";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/menu",
    name: "Menu",
    component: Menu,
  },
  {
    path: "/curbside",
    name: "Curbside Pickup",
    component: Curbside,
    meta: {
      title: "Curbside Pickup | " + defaultTitle,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    let scrollTo = 0;

    if (to.hash) {
      scrollTo = to.hash;
    } else if (savedPosition) {
      scrollTo = savedPosition.y;
    }

    let offset = scrollTo > 0 ? 0 : 48;

    if (to.path === from.path) {
      return goTo(scrollTo, {
        offset: offset,
      });
    } else {
      return setTimeout(() => {
        goTo(scrollTo, {
          offset: offset,
        });
      }, 500);
    }
  },
});

router.afterEach((to) => {
  Vue.nextTick(() => {
    if (to.hash) {
      document.title =
        to.hash.charAt(1).toUpperCase() +
        to.hash.slice(2) +
        " | " +
        defaultTitle;
    } else {
      document.title = to.meta.title || defaultTitle;
    }
  });
});

export default router;
