<template>
    <v-carousel
       cycle
       height="500"
       hide-delimiter-background
       show-arrows-on-hover
    >
       <v-carousel-item
       v-for="(slide, i) in slides"
       :key="i"
       eager
       >
          <v-parallax
            :src="slide.src"
            style="bottom:0; /* Aligns at the bottom */
 left:0;right:0; /* Aligns horizontal center */
 max-height:100%; /* images bigger than 175 px  */
 max-width:100%;  /* will be shrinked to size */ "
          >
            <v-row align="center" justify="space-between">
              <v-col cols="12" md="6">
                <v-col cols="12" class="title text-center">
                  <v-row align="center" justify="center">
                    <sequential-entrance delay="800">
                      <v-col cols="12">
                        <h2 class="primary--text strokeme">For weekly specials, <br />desserts, events, and more</h2>
                      </v-col>
                      <v-col cols="12">
                        <v-btn
                          x-large
                          color="secondary"
                          class="primary--text ma-2"
                          href="//www.facebook.com/profile.php?id=100086474891715"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <v-icon large class="mr-3">mdi-facebook</v-icon> Follow us on
                          Facebook
                          <v-icon class="ml-3 secondary--text text--darken-4"
                            >mdi-open-in-new</v-icon
                          ></v-btn
                        >
                      </v-col>
                      <!--
                      <v-col cols="12">
                        <h2>Or</h2>
                      </v-col>
                      <v-col cols="12">
                        <v-btn
                          x-large
                          color="primary"
                          class="secondary--text"
                          href="https://www.toasttab.com/pop-up-chicken-shop-409-n-hershey/rewardsSignup"
                          target="_blank"
                        >
                          Sign-Up for our Rewards Program!
                          <v-icon class="ml-3 secondary--text text--darken-3"
                            >mdi-arrow-down</v-icon
                          ></v-btn
                        >
                      </v-col>
                      -->
                    </sequential-entrance>
                  </v-row>
                </v-col>
              </v-col>
        
              <v-col
                cols="12"
                md="auto"
                class="d-none d-md-block mx-auto text-center animate__animated animate__fadeInLeft animate__delay-3s"
              >
                <!-- <v-card> -->
                <!-- <v-card-title class="primary secondary--text">
                    <h4>The Latest from Us on Facebook</h4>
                  </v-card-title> -->
                <iframe
                  src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fprofile.php%3Fid%3D100086474891715&tabs=timeline&width=340&height=500&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId"
                  width="450"
                  height="400"
                  style="border: none; overflow: hidden"
                  scrolling="no"
                  frameborder="0"
                  allowTransparency="true"
                  allow="encrypted-media"
                  title="The Latest from Us on Facebook"
                ></iframe>
                <!-- </v-card> -->
              </v-col>
            </v-row>
          </v-parallax>                
       </v-carousel-item>
    </v-carousel>
</template>
<script>
export default {
  name: "app-hero",

  data() {
    return {
        slides: [
         {
            id:1,
            src:'/img/hero_tray_loveshack.jpeg'
         },
         { 
            id:2,
            src: '/img/hero_basket_6.jpg'
         }
      ],
      scrollOptions: {
        offset: 53,
      },
      windowHeight: window.innerHeight,
      windowWidth: window.innerWidth,
    };
  },
};
</script>
<style lang="scss">
@import "~animate.css/source/_base.css";
@import "~animate.css/source/fading_entrances/fadeInLeft.css";
.strokeme {
  color: white;
  text-shadow: -2px -2px 0 #bf4215, 2px -2px 0 #bf4215, -2px 2px 0 #bf4215, 2px 2px 0 #bf4215;
}

h2 {
  font-family: "EckhardtPosterItalicJNL";
  color: #bf4215;
  letter-spacing: 0.25rem;
}
h4 {
  font-family: "EckhardtPosterItalicJNL";
  letter-spacing: 0.125em;
}
</style>
