import Vue from "vue";
import Vuetify from "vuetify/lib";
import "@fortawesome/fontawesome-free/css/all.css";

Vue.use(Vuetify);

const opts = {
  theme: {
    themes: {
      light: {
        primary: "#eea521",
        secondary: "#bf4215",
        accent: "#eea521",
      },
    },
  },
  icons: {
    iconfont: "fa",
  },
};

export default new Vuetify(opts);
