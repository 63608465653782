<template>
  <div class="white">
    <v-container>
      <v-row>
        <v-col cols="12">
          <v-alert type="info" color="primary" :value="true">
            Effective 11/4/20 we have switched to curbside pickup only
          </v-alert>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6">
          <v-card flat>
            <v-card-text>
              <h4 class="black--text text-center mb-2">
                How to Order Curbside Pickup
              </h4>
              <v-timeline align-top :dense="$vuetify.breakpoint.smAndDown">
                <v-timeline-item
                  icon="fas fa-phone"
                  icon-color="primary"
                  color="secondary"
                  fill-dot
                >
                  <template v-slot:opposite>
                    <span class="headline">
                      <h4><a href="tel:+1-309-827-3999">309-827-3999</a></h4>
                      or<br />
                      <h4><a href="tel:+1-309-821-1380">309-821-1380</a></h4>
                    </span>
                  </template>
                  <v-card color="primary">
                    <v-card-title class="primary pa-2">
                      <h4 class="secondary--text">Call Us</h4>
                    </v-card-title>
                    <v-card-text class="white pt-2 pb-1">
                      <p>
                        Place your order by calling us at
                        <a href="tel:+1-309-827-3999">309-827-3999</a> or
                        <a href="tel:+1-309-821-1380">309-821-1380</a>.
                      </p>
                      <p>Pay for your order by card over the phone</p>
                    </v-card-text>
                  </v-card>
                </v-timeline-item>
                <v-timeline-item
                  icon="fas fa-car"
                  color="primary"
                  icon-color="secondary"
                  fill-dot
                >
                  <v-card color="secondary">
                    <v-card-title class="secondary pa-2">
                      <h4 class="primary--text">Arrive at Pickup Time</h4>
                    </v-card-title>
                    <v-card-text class="white pt-2">
                      When ordering, you will select a 15-minute pickup window.
                    </v-card-text>
                  </v-card>
                </v-timeline-item>
                <v-timeline-item
                  icon="fas fa-comment-dots"
                  icon-color="primary"
                  color="secondary"
                  fill-dot
                >
                  <template v-slot:opposite>
                    <span class="headline">
                      <h4><a href="sms:+1-309-271-7554">309-271-7554</a></h4>
                    </span>
                  </template>
                  <v-card color="primary">
                    <v-card-title primary-title class="primary pa-2">
                      <h4 class="secondary--text">Text Us</h4>
                    </v-card-title>
                    <v-card-text class="white pt-2">
                      Upon arrival, text
                      <a href="sms:+1-309-271-7554">309-271-7554</a> with your
                      name and car. We'll respond confirming your order.
                    </v-card-text>
                  </v-card>
                </v-timeline-item>
                <v-timeline-item
                  icon="fas fa-drumstick-bite"
                  icon-color="secondary"
                  color="primary"
                  fill-dot
                >
                  <v-card color="secondary">
                    <v-card-title class="secondary pa-2">
                      <h4 class="primary--text">We'll Bring Your Food</h4>
                    </v-card-title>
                    <v-card-text class="white pt-2">
                      Stay in your car, and one of our staff will deliver your
                      food directly to you. Enjoy!
                    </v-card-text>
                  </v-card>
                </v-timeline-item>
              </v-timeline>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="6">
          <v-card flat>
            <v-card-text>
              <h4 class="black--text text-center mb-2">We Care About Safety</h4>
              <p>
                Due to the COVID-19 pandemic we are taking extra precautions to
                ensure the health and safety of our customers and staff.
              </p>
              <v-list>
                <v-list-item>
                  <v-list-item-avatar size="64">
                    <v-img src="/img/covid-mask-avatar.png"></v-img>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    Our staff will wear a mask during all customer interactions.
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-avatar size="64">
                    <v-img src="/img/covid-soap-avatar.png"></v-img>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    We've implemented additional personal sanitation protocols.
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-avatar size="64">
                    <v-img src="/img/covid-sanitizer-avatar.png"></v-img>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    We're cleaning and disinfecting all surfaces more
                    frequently.
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
export default {
  title: "Curbside Pickup",
};
</script>
<style lang="scss" scoped>
* {
  font-family: "Amaranth", sans-serif;
}

h4 {
  font-family: "EckhardtPosterItalicJNL", sans-serif;
  font-size: 16px;
  letter-spacing: 0.04rem;
}

h4 > a {
  font-family: "DayPosterBlack", cursive !important;
  font-size: 20px;
  //   font-weight: bold;
  color: #002156 !important;
  text-decoration: none;
  letter-spacing: 3px;
}

h4 > a:hover,
h4 > a:active {
  color: #002156 !important;
  text-shadow: -1px 1px #ed3825;
}
</style>
