<template>
  <v-app>
    <app-header />

    <app-bar app />

    <v-main class="mb-4 secondary">
      <router-view
        class="animate__animated animate__slideInLeft animate__faster"
      ></router-view>
    </v-main>

    <footer-nav />
  </v-app>
</template>

<script>
import AppBar from "@/components/nav/AppBar";
import FooterNav from "@/components/nav/FooterNav";
import AppHeader from "@/components/AppHeader";

export default {
  name: "app",

  components: {
    AppBar,
    AppHeader,
    FooterNav,
  },

  mounted() {
    if (window.scrollY > 172) {
      this.$store.commit("ui/appBarTrue");
    }
  },

  computed: {
    appBar() {
      return this.$store.state.appBar;
    },
  },
};
</script>

<style lang="scss">
@font-face {font-family: "XenoisSemiW01-HeavyItalic";
    src: url("//db.onlinewebfonts.com/t/40d224e30102ba2fa0cb3f09c3abf51a.eot"); /* IE9*/
    src: url("//db.onlinewebfonts.com/t/40d224e30102ba2fa0cb3f09c3abf51a.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
    url("//db.onlinewebfonts.com/t/40d224e30102ba2fa0cb3f09c3abf51a.woff2") format("woff2"), /* chrome firefox */
    url("//db.onlinewebfonts.com/t/40d224e30102ba2fa0cb3f09c3abf51a.woff") format("woff"), /* chrome firefox */
    url("//db.onlinewebfonts.com/t/40d224e30102ba2fa0cb3f09c3abf51a.ttf") format("truetype"), /* chrome firefox opera Safari, Android, iOS 4.2+*/
    url("//db.onlinewebfonts.com/t/40d224e30102ba2fa0cb3f09c3abf51a.svg#XenoisSemiW01-HeavyItalic") format("svg"); /* iOS 4.1- */
}

@import url("https://fonts.googleapis.com/css?family=Amaranth:700|Anton|Kanit:700|Libre+Baskerville&display=swap");

@font-face {
  font-family: "DayPosterBlack";
  src: url("/font/DAYPBL__-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "EckhardtPosterItalicJNL";
  src: url("/font/3AEE99_0_0.eot");
  src: url("/font/3AEE99_0_0.eot?#iefix") format("embedded-opentype"),
    url("/font/3AEE99_0_0.woff2") format("woff2"),
    url("/font/3AEE99_0_0.woff") format("woff"),
    url("/font/3AEE99_0_0.ttf") format("truetype");
  font-display: swap;
}

@font-face {
    font-family: "XenoisSemiW01-HeavyItalic"; src: url("//db.onlinewebfonts.com/t/40d224e30102ba2fa0cb3f09c3abf51a.eot"); src: url("//db.onlinewebfonts.com/t/40d224e30102ba2fa0cb3f09c3abf51a.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/40d224e30102ba2fa0cb3f09c3abf51a.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/40d224e30102ba2fa0cb3f09c3abf51a.woff") format("woff"), url("//db.onlinewebfonts.com/t/40d224e30102ba2fa0cb3f09c3abf51a.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/40d224e30102ba2fa0cb3f09c3abf51a.svg#XenoisSemiW01-HeavyItalic") format("svg"); 
    
}

body,
.v-application {
  font-family: "Libre Baskerville", "Kanit", "Roboto", "Arial", sans-serif !important;
}

.popup-heading {
  font-family: "XenoisSemiW01-HeavyItalic", cursive;
  font-size: 34px;
  letter-spacing: 4px;
  transform: scaleY(1.2);
  text-transform: uppercase;
  color: #bf4215;
}

.popup-title {
  font-size: 1.25rem !important;
  font-family: "XenoisSemiW01-HeavyItalic";
  color: #bf4215;
  letter-spacing: 0.25rem;
}

.v-content {
  background-color: #ed3825;
}

/* Ribbon */
.corner-ribbon {
  width: 200px;
  background: #002156;
  position: absolute;
  top: 25px;
  right: -50px;
  left: auto;
  font-family: "EckhardtPosterItalicJNL";
  text-align: center;
  line-height: 2rem;
  letter-spacing: 1px;
  color: #f0f0f0;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
}
</style>
