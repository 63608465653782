<template>
  <v-app-bar
    :app="app"
    color="primary"
    dark
    :inverted-scroll="app"
    :scroll-threshold="options.offset + 32"
  >
    <!-- <v-container fluid> -->
    <v-row align="center" justify="space-between" justify-md="space-around">
      <!-- <v-spacer></v-spacer> -->
      <v-col cols="auto">
        <v-btn
          :small="$vuetify.breakpoint.smAndDown"
          text
          link
          class="secondary--text btn-text"
          to="/#menu"
          >Menu</v-btn
        >
      </v-col>
      <v-col cols="auto">
        <v-btn
          href="https://www.toasttab.com/braize-2-1406-east-empire-wdjbm/v3/"
          target="_blank"
          :small="$vuetify.breakpoint.smAndDown"
          text
          link
          class="secondary--text btn-text"
          >Order Now!</v-btn
        >
      </v-col>
      <v-col cols="auto">
        <v-btn
          :small="$vuetify.breakpoint.smAndDown"
          text
          link
          class="secondary--text btn-text"
          to="/#location"
        >
          Location
        </v-btn>
      </v-col>
      <!-- <v-spacer></v-spacer> -->
    </v-row>
    <!-- </v-container> -->
  </v-app-bar>
</template>

<script>
export default {
  name: "app-bar",

  props: {
    app: Boolean,
  },

  data() {
    return {
      options: {
        offset: 52,
        duration: 500,
      },
    };
  },

  methods: {
    goHome() {
      let currRoute = this.$route.path;

      if (currRoute != "/") {
        this.$router.push("/");
      }
      this.$vuetify.goTo(0);
    },
    goMenu() {
      let currRoute = this.$route.path;

      if (currRoute != "/" && currRoute != "/menu") {
        this.$router.push("/");
        setTimeout(() => {
          this.$vuetify.goTo("#menu", this.options);
        }, 500);
      } else {
        this.$vuetify.goTo("#menu", this.options);
      }
    },
    goLocation() {
      let currRoute = this.$route.path;

      if (currRoute != "/" && currRoute != "/location") {
        this.$router.push("/");
        setTimeout(() => {
          this.$vuetify.goTo("#location", this.options);
        }, 500);
      } else {
        this.$vuetify.goTo("#location", this.options);
      }
    },
  },

  mounted() {
    if (this.app) {
      this.options.offset = document.getElementById("app-header").offsetHeight;
    }
  },
};
</script>

<style lang="scss" scoped>
.btn-text {
  font-size: 1.125rem !important;
}
</style>
