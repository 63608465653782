<template>
  <v-carousel
    hide-delimiters
    cycle
    :show-arrows="false"
    class="grey lighten-3 review-carousel"
    interval="8000"
  >
    <div class="hashtag secondary--text text-center mt-10 mb-n12">
      #KeepItPopUp
    </div>
    <br>
    <v-carousel-item
      v-for="(r, i) in shuffledReviews"
      :key="i"
      :transition="false"
    >
      <v-container class="fill-height" style="min-height:500px;">
        <v-row justify="center" align="center">
          <v-col cols="12" md="6">
            <v-rating
              v-model="r.rating"
              background-color="accent"
              class="text-center animate__animated animate__slideInLeft"
              color="secondary"
              :large="$vuetify.breakpoint.mdAndUp"
              readonly
              :aria-label="r.rating + ' stars'"
            >
            </v-rating>
            <v-col cols="auto" class="mx-auto">
              <a
                :href="r.permalink"
                target="_blank"
                rel="noreferrer"
                style="text-decoration: none"
              >
                <h1 class="secondary--text animate__animated animate__flipInX">
                  <small>"{{ r.review }}"</small>
                </h1>
              </a>
              <h3
                class="secondary--text text-right animate__animated animate__slideInRight"
              >
                - {{ r.user }}
              </h3>
              <h4
                class="secondary--text text-right animate__animated animate__slideInRight"
              >
                {{ r.city }}
              </h4>
            </v-col>
          </v-col>
        </v-row>
      </v-container>
    </v-carousel-item>
  </v-carousel>
</template>

<script>
export default {
  name: "review-carousel",

  data() {
    return {
      reviews: [
        {
          rating: 5,
          user: "Katie G.",
          review:
            "Had to see what all the hype was about and needless to say I was not one but disappointed. My fiancé and I got double cheeseburgers, frys, and chocolate milkshakes. The burger was one of the absolute best we’ve ever had. The milkshake made me feel so nostalgic… See more.",
          city: "Bloomington, IL",
          permalink:
            "https://www.facebook.com/people/The-Love-Shack/100086474891715/?sk=reviews",
        },
        {
          rating: 5,
          user: "Brian H.",
          review:
            "You grow up in a town like this and you often dream of someday enjoying a burger like the one at The Love Shack. Opening dayI stood in line because the boys & girls at Pop Up have a reputation and I knew it’d be top notch. When I saw those 3 patty’s, with their crispy edges, soft onions,  melted cheese… See more.",
          city: "Bloomington, IL",
          permalink:
            "https://www.facebook.com/people/The-Love-Shack/100086474891715/?sk=reviews",
        },
        {
          rating: 5,
          user: "Ashley B.",
          review:
            "Super yummy! The Pop-sauce was a nice touch to the burger! Not gonna lie though the cheese fries were probably my favorite!",
          city: "Normal, IL",
          permalink:
            "https://www.facebook.com/people/The-Love-Shack/100086474891715/?sk=reviews",
        },
        {
          rating: 5,
          user: "Mason D.",
          review:
            "Amazing food! Great atmosphere and soooo fast. Keep it pop up!",
          city: "Farmer City, IL",
          permalink:
            "https://www.facebook.com/people/The-Love-Shack/100086474891715/?sk=reviews",
        },
      ],
    };
  },

  computed: {
    shuffledReviews() {
      let array = this.reviews;

      for (var i = array.length - 1; i > 0; i--) {
        let j = Math.floor(Math.random() * (i + 1));
        let temp = array[i];
        array[i] = array[j];
        array[j] = temp;
      }

      return array;
    },
  },
};
</script>

<style lang="scss" scoped>
h1 {
  font-family: "EckhardtPosterItalicJNL";
  font-weight: 400;
  font-size: 1.33rem;
  @media (min-width: 960px) {
    font-size: 1.5rem;
  }
}

h3,
h4 {
  font-family: "Anton";
  font-weight: 400;
  opacity: 0.8;
}

.review-carousel::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.1875;
  background: url("/img/cow-icon-grayscale.png");
  background-attachment: fixed;
}

.hashtag {
  font-family: "Kanit";
  font-size: 2.5rem;
  letter-spacing: 0.25rem;
}
</style>
