// initial state
const state = {
  menuOverlay: false,
  appBar: false,
};

// getters
const getters = {};

// actions
const actions = {};

// mutations
const mutations = {
  appBarFalse: () => {
    state.appBar = false;
  },

  appBarTrue: () => {
    state.appBar = true;
  },

  toggleMenuOverlay: (state) => {
    state.menuOverlay = !state.menuOverlay;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
